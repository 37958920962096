<template>
  <!-- 买车页 -->
  <div ref="buyCar" class="buyCar">
    <!-- 遮罩层 -->
    <div class="buyCarBackground">
      <!-- 固定盒子1200 -->
      <div class="bigBuyCarBox">
        <!-- 买车标题 -->
        <div class="buyCarTitle">
          <span>买车</span>
          <span>purchase</span>
        </div>
        <!-- 列表内容 -->
        <div class="buyCarList">
          <div class="seeMore">
            <span></span>
            <span @click="seeMore" style="cursor: pointer">
              查看更多&nbsp;&raquo;
            </span>
          </div>
          <ul>
            <li v-for="(item, key) in list" :key="key">
              <img :src="item.image + '?imageView2/1/w/492/h/324'" alt="" />
              <div class="buyCarListTitle">
                {{ item.name || ' ' }}
              </div>
              <div class="describe">
                {{ (item.mileage / 10000).toFixed(2) }}万公里
                <span style="margin: 0 10px">|</span>
                {{ item.firstLicensingTime }}
                <span style="margin: 0 10px">|</span>
                {{ item.autopilotVersion }}
                <span style="margin: 0 10px">|</span>
                {{ item.city }}
              </div>
              <div class="price">
                <div class="Zprice">￥{{ Number(item.price) / 100 }}</div>
              </div>
              <div style="padding: 0 20px">
                <button
                  @click="LookDetails(item.assetCode)"
                  class="ButtonLook"
                  style="cursor: pointer"
                >
                  查看详情
                </button>
              </div>
              <div class="mask" v-show="item.assetCode == maskId">
                <!-- <div class="erweima" id="qrcode" ref="qrcode"></div> -->
                <img :src="img" alt="" style="border-radius: 6px" />
                <img
                  src="../../../src/assets/buyCar/close.png"
                  alt=""
                  @click="cancel"
                />
              </div>
            </li>
            <!-- <li>
              <img src="../../../src/assets/buyCar/car.png" alt="" />
              <div class="buyCarListTitle">
                2020 Model 3 标准续航后轮驱动升级
              </div>
              <div class="describe">10.79万公里丨2021年首次上牌丨FSD</div>
              <div class="price">
                <div class="Zprice">￥268,000</div>
                <div class="downPayments">首付6.12万</div>
              </div>
              <button>查看详情</button>
            </li>
            <li>
              <img src="../../../src/assets/buyCar/car.png" alt="" />
              <div class="buyCarListTitle">
                2020 Model 3 标准续航后轮驱动升级
              </div>
              <div class="describe">10.79万公里丨2021年首次上牌丨FSD</div>
              <div class="price">
                <div class="Zprice">￥268,000</div>
                <div class="downPayments">首付6.12万</div>
              </div>
              <button>查看详情</button>
            </li> -->
          </ul>
        </div>
        <!-- 流程 -->
        <div class="technologicalProcess">
          <ul>
            <li>出价交撮</li>
            <li>
              <img src="../../../src/assets/buyCar/Redarrow.png" alt="" />
            </li>
            <li>车辆过户</li>
            <li>
              <img src="../../../src/assets/buyCar/Redarrow.png" alt="" />
            </li>
            <li>全国配送</li>
            <li>
              <img src="../../../src/assets/buyCar/Redarrow.png" alt="" />
            </li>
            <li>货到付款</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { indexList, code } from '../../../src/api/home'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      maskId: null,
      img: '',
      list: []
    }
  },
  mounted() {
    this.getCarList()
  },
  methods: {
    //获取推荐车辆列表
    async getCarList() {
      let res = await indexList()
      console.log(res)
      if (res.errorCode == '00000') {
        let buyCarList = res.data.items
        this.list = buyCarList.slice(0, 3)
      }
    },
    // 查看详情
    async LookDetails(id) {
      var res = await code(id)
      console.log(res)
      const blob = new Blob([res])
      console.log(blob, 'blob')
      const url = window.URL.createObjectURL(blob)
      console.log(url, 'url')

      this.img = url
      // console.log(res, "res");
      this.maskId = id

      // code(id).then(res => {
      //   console.log(res)
      //   const blob = new Blob([res])
      //   const url = window.URL.createObjectURL(blob)
      //   this.img = url
      //   // console.log(res, "res");
      //   this.maskId = id
      // })

      // console.log(this.maskId, "this.maskId");
    },
    // 弹出层上的取消按钮
    cancel() {
      this.maskId = null
    },
    // 查看更多
    seeMore() {
      this.maskId = null
      this.$router.push('/buyCarList')
    }
  },

  filters: {
    moneyFormat: function (arg) {
      if (arg === null) {
        return false
      }
      if (arg.toString().length >= 5) {
        const moneys = arg / 1000000
        const realVal = parseFloat(moneys).toFixed(2)
        return realVal + '万'
      } else if (arg.toString().length < 5) {
        const moneys = arg / 100
        return moneys + '元'
      } else {
        return arg
      }
    }
  },
  computed: {
    ...mapState(['cityLength'])
  }
}
</script>

<style lang="scss">
.buyCar {
  width: 100%;
  background: url(../../../src/assets/backgroundImg/buyCar.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.buyCarBackground {
  width: 100%;
  background: rgba(255, 255, 255, 0.93);
  display: flex;
  justify-content: center;
}

.bigBuyCarBox {
  width: 1200px;

  // 买车标题
  .buyCarTitle {
    margin-top: 207px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span:nth-child(1) {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 56px;
      color: #272727;
    }

    span:nth-child(2) {
      font-family: Nexa Heavy;
      font-style: normal;
      font-weight: 900;
      font-size: 60px;
      line-height: 60px;
      text-transform: uppercase;
      color: #ededed;
      margin-bottom: 13px;
    }
  }

  // 买车列表点击显示遮罩层
  .mask {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 446px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    img:nth-child(1) {
      margin: 84px 0 45px 0;
      width: 200px;
      height: 200px;
    }

    img:nth-child(2) {
      width: 15.56px;
      height: 15.56px;
    }
  }

  // 买车列表
  .buyCarList {
    display: flex;
    flex-direction: column;

    .seeMore {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    ul > li:hover {
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    }

    ul {
      display: flex;
      justify-content: space-between;
      padding: 0;

      li {
        background: #fff;
        position: relative;
        list-style: none;
        width: 32%;
        height: 446px;

        // background-color: blueviolet;
        img {
          width: 100%;
          height: 55%;
          margin-bottom: 16px;
        }

        .buyCarListTitle {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0px 16px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          color: #1e2a3c;
          margin-bottom: 8px;
        }

        .describe {
          margin: 0px 16px;
          margin-bottom: 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 16px;
          color: #6d7580;
        }

        .price {
          margin: 0px 16px;
          margin-bottom: 24px;
          display: flex;
          align-items: flex-end;

          .Zprice {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 28px;
            text-align: center;
            color: #c90000;
            margin-right: 8px;
          }

          .downPayments {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #c90000;
          }
        }

        .ButtonLook:hover {
          background-color: #343f50;
        }

        .ButtonLook {
          background: #1e2a3c;
          border-radius: 20px;
          // margin: 0px 16px;
          width: 100%;
          height: 39px;
          border: 0;
          color: #ffffff;
          outline: none;
          // margin-bottom: 25px;
        }
      }
    }
  }

  // 流程
  .technologicalProcess {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 96px;

    ul {
      padding: 0;
      display: flex;
      align-items: center;

      li {
        list-style: none;
        display: flex;
        align-items: center;
      }

      li:nth-child(odd) {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 32px;
        color: #333333;
      }

      li:nth-child(even) {
        margin: 0 20px;
        width: 50px;
        height: 29px;

        img {
          width: 50px;
          height: 29px;
        }
      }
    }
  }
}

.RightsAndInterests {
  display: flex;
  justify-content: space-around;

  .RightsAndInterestsOne:hover {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

  .RightsAndInterestsOne {
    width: 509px;
    height: 190px;
    padding: 34px 24px 0 24px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    border: 1px solid #666666;
    box-sizing: border-box;

    div {
      text-align: center;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #1e2a3c;
      margin-bottom: 26px;
    }

    span {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #1e2a3c;
    }
  }
}
</style>
