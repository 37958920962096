import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API2,
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['token'] = ''
    config.headers['appType'] = 'miniprogram'
    config.headers['plat'] = ''
    config.headers['imei'] = ''
    config.headers['oaid'] = ''
    config.headers['appvn'] = ''
    config.headers['anid'] = ''
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

let timer
// response 拦截器
service.interceptors.response.use(
  async response => {
    if (response.data.bizCode == 'LSEV-400-B0306') {
      uni.setStorageSync('token', '')
      uni.setStorageSync('loginPhone', '')
      uni.navigateTo({
        url: '/pages/login/index'
      })
    }
    return Promise.resolve(response.data)
  },
  error => {
    // tryHideFullScreenLoading()
    // uni.hideLoading();
    if (error == 'Error') {
      // let pages = getCurrentPages()
      // let routers = pages[pages.length - 1].route
      // if (routers != 'pages/login/network') {
      // 	uni.navigateTo({
      // 		url: '/pages/login/network'
      // 	})
      // }
    }
    return Promise.reject(error)
  }
)

export default service
