<template>
  <div class="home" id="Hom">
    <!--首页面布局-->
    <el-container>
      <!-- 头部 -->
      <el-header style="position: fixed; top: 0; left: 0; z-index: 500">
        <!-- 最外面的盒子 -->
        <div class="bigBox">
          <!-- logo部分 -->
          <div>
            <img src="../../src/assets/homeImg/logo.png" alt="" />
          </div>
          <!-- 切换部分 -->
          <div>
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              background-color="#0c0c0c"
              text-color="#FFFFFF"
              active-text-color="#FFFFFF"
            >
              <el-menu-item
                index="1"
                :disabled="disabledLsev"
                @click="goToView('home')"
              >
                雷神
              </el-menu-item>
              <el-menu-item
                index="2"
                :disabled="disabledBuyCar"
                @click="goToView('buyCar')"
                class="disBuyCar"
              >
                买车
              </el-menu-item>
              <el-menu-item
                index="3"
                :disabled="disabledSellCar"
                @click="goToView('sellCar')"
                class="disSellCar"
              >
                卖车
              </el-menu-item>
              <el-menu-item
                index="4"
                :disabled="disabledAboutUs"
                @click="goToView('aboutUs')"
                class="disAboutUs"
              >
                关于我们
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-main>
        <lsev></lsev>
        <buyCar ref="buyCarBox" @maskId="mask($event)"></buyCar>
        <sellCar ref="sellCarBox"></sellCar>
        <aboutUs ref="aboutUsBox"></aboutUs>
        <!-- 底部 -->
        <div class="footer">
          <Footer></Footer>
          <!-- 固定宽度1200 -->
          <!-- <div class="footerBox">
            上面部分
            <div class="footerBoxtop">
              <span>联系电话：010-81401798</span>
              <span>商务合作：bd@lsev.com</span>
              <span>北京仓地址：北京市昌平区北辰亚运村二手车市场五区1号</span>
            </div>
            下面部分
            <div class="footerBoxBottom">
              <span>Copyright © 2021 雷神 All Rights Reserved </span>
              <div>
                <a href="https://beian.miit.gov.cn/" target="_blank"
                  >京ICP备2021017035号-1</a
                >
                <router-link
                  :to="{ path: '/licence' }"
                  style="margin-left: 30px"
                  target="_blank"
                >
                  增值电信业务经营许可证：京B2-20212260
                </router-link>
                <img src="../../src/assets/homeImg/gongan.png" />
                <a
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502044953"
                  target="_blank"
                  style="margin-left: 44px"
                  >京公网安备11010502044953号</a
                >
              </div>
            </div>
          </div> -->
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import lsev from '.././components/home/lsev.vue'
import buyCar from '.././components/home/buyCar.vue'
import sellCar from '.././components/home/sellCar.vue'
import aboutUs from '.././components/home/aboutUs.vue'
import Footer from '.././components/home/footer.vue'
// import { Message } from "element-ui";
export default {
  components: {
    lsev,
    buyCar,
    sellCar,
    aboutUs,
    Footer
  },
  data() {
    return {
      activeIndex: '1',
      maskD: null,
      disabledLsev: false,
      disabledBuyCar: false,
      disabledSellCar: false,
      disabledAboutUs: false,
      url: 'https://cdn.lsev.com/pc/Lark20210722-124133.png',
      // scrollToTop: 0,
      buyCarTop: 0,
      sellCarTop: 0,
      aboutUsTop: 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.buyCarTop = this.$refs.buyCarBox.$refs.buyCar.offsetTop
      this.sellCarTop = this.$refs.sellCarBox.$refs.sellCar.offsetTop
      this.aboutUsTop = this.$refs.aboutUsBox.$refs.aboutUs.offsetTop
      console.log(this.buyCarTop)
      console.log(this.sellCarTop)
      console.log(this.aboutUsTop)
    }, 500)

    window.addEventListener('scroll', () => {
      let height = document.documentElement.scrollTop || document.body.scrolltop
      if (height >= 0) {
        this.activeIndex = '1'
      }
      if (height >= this.buyCarTop) {
        this.activeIndex = '2'
      }
      if (height >= this.sellCarTop) {
        this.activeIndex = '3'
      }
      if (height >= this.aboutUsTop) {
        this.activeIndex = '4'
      }
    })
  },
  methods: {
    getBrowser() {
      var UserAgent = navigator.userAgent.toLowerCase()
      var browserInfo = {}
      var browserArray = {
        IE: window.ActiveXObject || 'ActiveXObject' in window, // IE
        Chrome:
          UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1, // Chrome浏览器
        Firefox: UserAgent.indexOf('firefox') > -1, // 火狐浏览器
        Opera: UserAgent.indexOf('opera') > -1, // Opera浏览器
        Safari:
          UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') == -1, // safari浏览器
        Edge: UserAgent.indexOf('edge') > -1, // Edge浏览器
        QQBrowser: /qqbrowser/.test(UserAgent), // qq浏览器
        WeixinBrowser: /MicroMessenger/i.test(UserAgent) // 微信浏览器
      }
      for (var i in browserArray) {
        if (browserArray[i]) {
          var versions = ''
          if (i == 'IE') {
            versions = UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)[2]
          } else if (i == 'Chrome') {
            for (var mt in navigator.mimeTypes) {
              //检测是否是360浏览器(测试只有pc端的360才起作用)
              if (
                navigator.mimeTypes[mt]['type'] ==
                'application/360softmgrplugin'
              ) {
                i = '360'
              }
            }
            versions = UserAgent.match(/chrome\/([\d.]+)/)[1]
          } else if (i == 'Firefox') {
            versions = UserAgent.match(/firefox\/([\d.]+)/)[1]
          } else if (i == 'Opera') {
            versions = UserAgent.match(/opera\/([\d.]+)/)[1]
          } else if (i == 'Safari') {
            versions = UserAgent.match(/version\/([\d.]+)/)[1]
          } else if (i == 'Edge') {
            versions = UserAgent.match(/edge\/([\d.]+)/)[1]
          } else if (i == 'QQBrowser') {
            versions = UserAgent.match(/qqbrowser\/([\d.]+)/)[1]
          }
          browserInfo.type = i
          browserInfo.versions = parseInt(versions)
        }
      }
      return browserInfo
    },
    lsev() {
      var cHeight = document.documentElement.clientHeight
      if (cHeight < 1000) {
        document.documentElement.scrollTop = 0
        this.activeIndex = '1'
      } else if (cHeight > 1000) {
        document.documentElement.scrollTop = 0
        this.activeIndex = '1'
      }
    },
    goToView(el) {
      if (
        this.getBrowser().type == 'Safari' &&
        this.getBrowser().versions <= 11
      ) {
        this.disabledBuyCar = true
        this.$message({
          message: '当前版本过低，请升级版本',
          type: 'warning'
        })
        document.querySelector('.disBuyCar').style.borderBottom = '0'
      } else {
        if (el === 'home') {
          this.activeIndex = '1'
          document.documentElement.scrollTop = 0
        } else if (el === 'buyCar') {
          this.activeIndex = '2'
          document.documentElement.scrollTop = this.buyCarTop
        } else if (el === 'sellCar') {
          this.activeIndex = '3'
          document.documentElement.scrollTop = this.sellCarTop
        } else if (el === 'aboutUs') {
          this.activeIndex = '4'
          document.documentElement.scrollTop = this.aboutUsTop
        }
      }
    }
  }
}
</script>

<style lang="scss">
.imgScreen {
  position: relative;
}
.bigBox {
  width: 1200px;
  height: 60px;
  display: flex;
  justify-content: center;
  // background: pink;
  align-items: center;
  justify-content: space-between;
  div:nth-child(1) {
    img {
      margin-top: 6px;
      width: 62px;
      height: 26px;
    }
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
  width: 100%;
  height: 100%;
  display: flex;
}
// 头部样式
.el-header {
  width: 100%;
  height: 60px;
  left: 0px;
  top: 0px;
  background: #0c0c0c;
  display: flex;
  justify-content: center;
}
// 点击后线条的显示
.el-menu-item {
  padding: 0 !important;
  margin-left: 101px !important;
  height: 30px !important;
  line-height: 30px !important;
}
.el-main {
  padding: 0 !important;
  -webkit-overflow-scrolling: touch;
}
// 底部内容
.footer {
  width: 100%;
  height: 275px;
  background: #333333;
  display: flex;
  justify-content: center;
}
.footerBox {
  width: 1200px;
  height: 275px;
  // background: pink;
}
.footerBoxtop {
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 81px;
  span {
    display: flex;
    justify-content: center;
    width: 386px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
  }
}
.footerBoxBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
    color: #ffffff;
  }
  span {
    font-family: PingFang SC;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
  }
  div {
    font-family: PingFang SC;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    position: relative;
    margin-top: 24px;
    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
      position: absolute;
      right: 27.5%;
      top: -2px;
    }
  }
}
</style>
