<template>
  <!-- 卖车页 -->
  <div ref="sellCar" class="sellCar">
    <!-- 白色遮罩层 -->
    <div class="sellCarBackground">
      <!-- 固定宽度1200 -->
      <div class="bigSellCarBox">
        <!-- 左边内容 -->
        <div class="sellCarleft">
          <span>卖车</span>
          <span>Selling</span>
          <img src="https://cdn.lsev.com/ows/sellcar_qrcode.png" alt="" />
        </div>
        <!-- 右边内容 -->
        <div class="sellCarright">
          <!-- 文字区域 -->
          <div class="writtenWords">
            <!-- 上半部分 -->
            <div class="sellCarrightTitle">
              <img src="../../../src/assets/sellcar/logo.png" alt="" />
              <span>新能源二手车交易所</span>
              <span>极速报价 · 当日收款</span>
            </div>
            <!-- 下半部分 -->
            <div class="sellCarrightbottom">
              <ul>
                <li>
                  <img
                    src="../../../src/assets/sellcar/information.png"
                    alt=""
                  />
                  <span>填写信息</span>
                </li>
                <li>
                  <img
                    src="../../../src/assets/sellcar/line.png"
                    alt=""
                    class="lineImg"
                  />
                </li>
                <li>
                  <img src="../../../src/assets/sellcar/offer.png" alt="" />
                  <span>在线报价</span>
                </li>
                <li>
                  <img src="../../../src/assets/sellcar/line.png" alt="" />
                </li>

                <li>
                  <img src="../../../src/assets/sellcar/testing.png" alt="" />
                  <span>上门检测</span>
                </li>
                <li>
                  <img src="../../../src/assets/sellcar/line.png" alt="" />
                </li>

                <li>
                  <img
                    src="../../../src/assets/sellcar/Collection.png"
                    alt=""
                  />
                  <span>当日收款</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- 图片区域 -->
          <div class="carImg">
            <img
              src="../../../src/assets/sellcar/car.png"
              alt=""
              style="width: 468px; height: 839px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.sellCar {
  width: 100%;
  height: 940px;
  background: url(../../../src/assets/backgroundImg/sellCar.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.sellCarBackground {
  width: 100%;
  height: 940px;
  background: rgba(255, 255, 255, 0.93);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  .bigSellCarBox {
    width: 1200px;
    // background: pink;
    display: flex;
    .sellCarleft {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      :nth-child(1) {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 56px;
        color: #272727;
        margin-top: 280px;
        margin-bottom: 8px;
      }
      :nth-child(2) {
        font-family: Nexa Heavy;
        font-style: normal;
        font-weight: 900;
        font-size: 60px;
        line-height: 60px;
        text-transform: uppercase;
        color: #ededed;
        margin-bottom: 54px;
      }
      :nth-child(3) {
        width: 200px;
        height: 200px;
      }
    }
    .sellCarright {
      width: 55%;
      display: flex;
      .writtenWords {
        .sellCarrightTitle {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          :nth-child(1) {
            width: 92px;
            height: 32px;
            margin-bottom: 12px;
            margin-top: 180px;
          }
          :nth-child(2) {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            line-height: 24px;
            color: #1e2a3c;
            margin-bottom: 12px;
            width: 216px;
          }
          :nth-child(3) {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #1e2a3c;
            margin-bottom: 86.69px;
          }
        }
        .sellCarrightbottom {
          ul {
            display: flex;
            flex-direction: column;
            // align-items: center;
            padding-left: 40px;
            li {
              list-style: none;
              display: flex;
            }
            li:nth-child(odd) {
              display: flex;
              align-items: center;
              img {
                width: 48px;
                height: 48px;
                margin-right: 12px;
              }
            }
            li:nth-child(even) {
              margin: 16px 0px 16px 23px;
              img {
                width: 0.5px;
                height: 38px;
              }
              span {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
                color: #1e2a3c;
              }
            }
          }
        }
      }
      .carImg {
        margin-left: 46px;
        img {
          margin-top: 70px;
          // width: 311px;
          // height: 720px;
        }
      }
    }
  }
}
</style>
