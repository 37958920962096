<template>
  <!-- 关于我们 -->
  <div ref="aboutUs" class="aboutUs">
    <!-- 固定宽度1200 -->
    <div class="bigaboutUsBox">
      <span class="aboutUsTitle">关于我们</span>
      <span class="aboutUsEnglish">about us</span>
      <span class="describe">
        雷神是专注于新能源二手车的交易所，所有主流新能源品牌车型都在这里汇聚。
        <br />
        买家卖家在线议价，直接交易。
      </span>
      <!-- 可交付城市 -->
      <div class="deliveryCity">
        <!-- 标题 -->
        <p class="deliveryCityTitle">雷神全国可交付城市</p>
        <div class="deliveryCityContent">
          <div v-for="(item, key) in list" :key="key" class="province">
            <div class="provinceName">{{ item.name }}</div>
            <div class="city">
              <span
                style="margin-bottom: 5px"
                v-for="(i, k) in item.children"
                :key="k"
              >
                {{ i.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <img src="https://cdn.lsev.com/ows/QRcode.png" alt="" class="QRcode" />
      <span class="follow">扫二维码关注我们</span>
    </div>
  </div>
</template>

<script>
import { City } from '../../../src/api/home'
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      list: [],
      length: 0
    }
  },
  created() {
    City().then(res => {
      // console.log(res.data.data);
      this.list = res.data
      for (let i = 0; i < this.list.length; i++) {
        // console.log(this.list[i].children);
        this.length += this.list[i].children.length
      }
      this.CLength(this.length)
    })
  },
  methods: {
    ...mapMutations(['CLength'])
  }
}
</script>

<style lang="scss">
.aboutUs {
  width: 100%;
  min-height: 1118px;
  padding-bottom: 80px;
  background: url(../../../src/assets/backgroundImg/aboutUs.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}

.bigaboutUsBox {
  width: 1200px;
  min-height: 1118px;
  // background: pink;
  display: flex;
  flex-direction: column;
  align-items: center;

  .aboutUsTitle {
    margin-top: 190px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    color: #272727;
    margin-bottom: 8px;
  }

  .aboutUsEnglish {
    font-family: Nexa Heavy;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 60px;
    text-transform: uppercase;
    color: #ededed;
    margin-bottom: 23px;
  }

  .describe {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #1e2a3c;
    width: 875px;
    margin-bottom: 64px;
  }

  .deliveryCity {
    width: 100%;
    margin-bottom: 100px;
    // width: 1061px;
    // height: 221px;
    // margin-bottom: 100px;
    // 交付城市的名字
    .deliveryCityTitle {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      color: #1e2a3c;
      text-align: center;
      margin: 0;
      margin-bottom: 33px;
    }

    // 交付城市的内容
    .deliveryCityContent {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-around;
      align-items: center;
      // margin-right: 16px;
      .province {
        height: 40px;
        display: flex;
        width: 32%;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #1e2a3c;
        // justify-content: space-around;
        margin-right: 16px;
        margin-bottom: 16px;
        .provinceName {
          flex: 0 0 40px;
          margin-right: 16px;
        }
        .city {
          flex: 1;
          span {
            display: inline-block;
            font-weight: 400;
            padding-right: 16px;
          }
        }
      }
    }
  }

  .QRcode {
    width: 160px;
    height: 160px;
    margin-bottom: 8px;
  }

  .follow {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #000000;
  }
}
</style>
