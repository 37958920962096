import request from '@/utils/request.js'
import requestManager from '@/utils/requestManager.js'
import request2 from '@/utils/request2.js'

// 获取客服电话
export const getKfPhone = function (params) {
  return request({
    url: '/api/v1/kfPhoneUpdateRecord/getKfPhone',
    method: 'get',
    params
  })
}

// 买车接口
export const indexList = function (params) {
  return request2({
    url: '/v2/asset/pc/recommendation',
    method: 'get',
    headers: { token: '' },
    params
  })
}

// 交付城市
export const City = function (data) {
  return requestManager({
    url: '/openapi/v1/offcialWebsite/city',
    method: 'get',
    data
  })
}

// 二维码
export const code = function (data) {
  return request2({
    url: '/v2/asset/pc/mp_detail_code?code=' + data,
    method: 'get',
    responseType: 'blob',
    data
  })
}
