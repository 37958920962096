<template>
  <!-- 雷神页 -->
  <div class="lsev" style="margin-top: 60px">
    <!-- 背景图 -->
    <div class="lsevBackground"></div>
    <!-- 遮罩层 -->
    <div class="background">
      <!-- 最外层的盒子 -->
      <div class="biglsevBox">
        <!-- 左边图片 -->
        <div class="leftImg">
          <img src="https://cdn.lsev.com/ows/banner.png" alt="" />
        </div>
        <!-- 右边内容 -->
        <div class="rightContent">
          <ul>
            <li><img src="https://cdn.lsev.com/ows/logo.png" alt="" /></li>
            <li>专注于新能源二手车的交易所</li>
            <li>the new way to buy EV</li>
            <li>
              <div style="width: 170px;height: 170px;background: #ffffff;border-radius: 10px;margin-bottom: 18px">
                <img src="https://cdn.lsev.com/ows/buycar_qrcode.png" alt="" />
              </div>
              <p style="text-indent: 6px">微信扫码 在线选车</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.biglsevBox {
  width: 1200px;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.lsev {
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
}
.background {
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
}
.lsevBackground {
  width: 100%;
  height: calc(100vh - 60px);
  background: url(../../../src/assets/backgroundImg/lsev.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftImg {
  // margin-top: 208px;
  // margin: 208px 157px 246px 467px;
  width: 443px;
  height: 485.88px;
  display: flex;
  justify-content: flex-end;
  img {
    width: 100%;
    height: 100%;
  }
}
.rightContent {
  margin-top: 52px;
  ul {
    li {
      list-style: none;
    }
    li:nth-child(1) {
      img {
        width: 137px;
        height: 47px;
        margin-bottom: 27px;
      }
    }
    li:nth-child(2) {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 45px;
      color: #ffffff;
      margin-bottom: 16px;
    }
    li:nth-child(3) {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 35px;
    }
    li:nth-child(4) {
      img {
        width: 160px;
        height: 160px;
        background: #fff;
        margin: 5px 5px;
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
        padding-left: 5px;
      }
    }
  }
}
</style>
