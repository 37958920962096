<template>
  <div class="buyCarList">
    <!--列表页-->
    <el-container>
      <!-- 头部 -->
      <el-header style="position: fixed; top: 0; left: 0; z-index: 200">
        <!-- 最外面的盒子 -->
        <div class="bigBox">
          <!-- logo部分 -->
          <div>
            <img src="../../src/assets/buyCar/logo.png" alt="" @click="backHistory()" style="cursor: pointer" />
          </div>
          <!-- 返回图片 -->
          <div class="leftArrow" @click="backHistory">
            <img src="../../src/assets/buyCar/left.png" alt="" style="width: 16px; height: 16px" />
          </div>
          <!-- 切换部分 -->
          <div></div>
        </div>
      </el-header>
      <!-- 列表 -->
      <el-main style="background: #f5f5f5; margin-top: 60px">
        <!-- 固定宽度1200 -->
        <div class="bigBoxList">
          <div class="buyCarListContent">
            <div class="buyCarListOne" v-for="(item, key) in Buylist" :key="key">
              <img :src="item.image + '?imageView2/1/w/492/h/324'" alt="" />
              <div class="title">
                {{ item.name || ' ' }}
              </div>
              <div class="firstLicensing">
                {{ (item.mileage / 10000).toFixed(2) }}万公里<span style="margin:0 10px;">|</span>{{
                    item.firstLicensingTime
                }}<span style="margin:0 10px;">|</span>{{ item.autopilotVersion }}<span
                  style="margin:0 10px;">|</span>{{ item.city }}
              </div>
              <div class="price">
                <span>
                  ￥{{ (Number(item.price) / 100) }}
                </span>
                <button class="seebutton" @click="buyCarDetails(item.assetCode)" style="cursor: pointer">
                  查看详情
                </button>
                <!-- </div> -->
              </div>
              <!-- 遮罩层 -->
              <div class="mask" v-show="item.assetCode == flagMack">
                <img :src="image" alt="" />
                <img src="../../src/assets/buyCar/close.png" alt="" @click="cancelMack" />
              </div>
            </div>
          </div>
          <!-- 查看更多车源 -->
          <div class="LookMoreCar">
            <button @click="MoreCar" style="cursor: pointer">更多车源
            </button>
            <img src="https://cdn.lsev.com/ows/buycar_qrcode.png" alt="" v-if="show" />
          </div>
        </div>
      </el-main>
    </el-container>
    <div class="footer">
      <Footer></Footer>
      <!-- <div class="footerBox">
        <div class="footerBoxtop">
          <span>联系电话：010-81401798</span>
          <span>商务合作：bd@lsev.com</span>
          <span>北京仓地址：北京市昌平区北辰亚运村二手车市场五区1号</span>
        </div>
        <div class="footerBoxBottom">
          <span>Copyright © 2021 雷神 All Rights Reserved </span>
          <div>
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >京ICP备2021017035号-1</a
            >
            <router-link
              :to="{ path: '/licence' }"
              style="margin-left: 30px"
              target="_blank"
            >
              增值电信业务经营许可证：京B2-20212260
            </router-link>
            <img src="../../src/assets/homeImg/gongan.png" />
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502044953"
              target="_blank"
              style="margin-left: 44px"
              >京公网安备11010502044953号</a
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { indexList, code } from '../api/home'

import Footer from '.././components/home/footer.vue'

export default {
  components: {
    Footer
  },
  data() {
    return {
      show: false,
      Buylist: [],
      flagMack: null,
      image: ''
    }
  },
  mounted() {
    this.getCarList()
  },
  methods: {
    //获取推荐车辆列表
    async getCarList() {
      let res = await indexList()
      console.log(res);
      if (res.errorCode == '00000') {
        let buyCarList = res.data.items
        this.Buylist = buyCarList.slice(0, 11)
      }
    },
    // 返回上一页
    backHistory() {
      // this.$router.back();
      history.back()
    },
    MoreCar() {
      this.show = true
    },
    // 点击进详情
    async buyCarDetails(id) {
      var res = await code(id)
      console.log(res)
      const blob = new Blob([res])
      console.log(blob, 'blob')
      const url = window.URL.createObjectURL(blob)
      console.log(url, 'url')

      this.image = url
      // console.log(res, "res");
      this.flagMack = id
    },
    // 点击取消
    cancelMack() {
      this.flagMack = null
    }
  },
  filters: {
    moneyFormat: function (arg) {
      if (arg === null) {
        return false
      }
      if (arg.toString().length >= 5) {
        const moneys = arg / 1000000
        const realVal = parseFloat(moneys).toFixed(2)
        return realVal + '万'
      } else if (arg.toString().length < 5) {
        const moneys = arg / 100
        return moneys + '元'
      } else {
        return arg
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bigBox {
  // background: #e5e5e5;
  position: relative;
  width: 1200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: pink;
  align-items: center;
  justify-content: space-between;

  div:nth-child(1) {
    margin-left: 46px;

    img {
      margin-top: 6px;
      width: 62px;
      height: 26px;
    }
  }
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
}

// 头部样式
.el-header {
  width: 100%;
  height: 60px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.leftArrow {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 12px;
  width: 22px;
  height: 22px;
  // background: #FFFFFF;
  // box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.el-main {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.bigBoxList {
  background: #f5f5f5;
  padding: 120px 80px;
  width: 1200px;
  box-sizing: border-box;
}

.buyCarListContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.buyCarListOne:hover {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
}

.buyCarListOne {
  position: relative;
  background: #ffffff;
  box-sizing: border-box;
  width: 46%;
  height: 477px;
  margin-bottom: 60px;

  img {
    width: 100%;
    height: 321px;
    margin-bottom: 24px;
  }

  .title {
    margin: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #1e2a3c;
    margin-bottom: 8px;
  }

  .firstLicensing {
    margin: 0 20px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #6d7580;
    margin-bottom: 24px;
  }

  .price {
    margin: 0 0 0 20px;

    // display: flex;
    // align-items: center;
    :nth-child(1) {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 28px;
      text-align: center;
      color: #c90000;
      margin-right: 8px;
    }

    span:nth-child(2) {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: #c90000;
    }

    .seebutton:hover {
      background-color: #343f50;
    }

    .seebutton {
      margin-right: 11px;
      float: right;
      width: 173px;
      height: 39px;
      background: #1e2a3c;
      border-radius: 20px;
      border: 0;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #ffffff;
      outline: none;
    }
  }

  // 遮罩层
  .mask {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 477px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    img:nth-child(1) {
      margin: 84px 0 45px 0;
      width: 250px;
      height: 250px;
      border-radius: 6px;
    }

    img:nth-child(2) {
      width: 15.56px;
      height: 15.56px;
    }
  }
}

.LookMoreCar {
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin-top: 30px;

  button {
    width: 120px;
    height: 32px;
    background: #1e2a3c;
    border-radius: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    border: 0;
    outline: none;
    margin-bottom: 26px;
  }

  img {
    width: 160px;
    height: 160px;
  }
}

// 底部内容
.footer {
  width: 100%;
  height: 275px;
  background: #333333;
  display: flex;
  justify-content: center;

  .footerBox {
    width: 1200px;
    height: 275px;
    // background: pink;
  }
}

.footerBoxtop {
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 81px;

  span {
    display: flex;
    justify-content: center;
    width: 386px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
  }
}

.footerBoxBottom {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
    color: #ffffff;
  }

  span {
    font-family: PingFang SC;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
  }

  div {
    font-family: PingFang SC;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    position: relative;
    margin-top: 24px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
      position: absolute;
      right: 27.5%;
      top: -2px;
    }
  }
}
</style>
