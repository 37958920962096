<template>
  <div class="footerBox">
    <!-- 上面部分 -->
    <div class="footerBoxtop">
      <!-- <span>联系电话：010-81401798</span> -->
      <span>商务合作：bd@lsev.com</span>
      <span>广州分所：广州市海珠区新滘中路199号GJ-MC-E5至E10</span>
    </div>
    <!-- 下面部分 -->
    <div class="footerBoxBottom">
      <span>Copyright © 2021-{{ year }} 雷神 All Rights Reserved</span>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          京ICP备2021017035号-1
        </a>
        <router-link
          :to="{ path: '/licence' }"
          style="margin-left: 30px"
          target="_blank"
        >
          增值电信业务经营许可证：京B2-20212260
        </router-link>
        <img src="../../assets/homeImg/gongan.png" />
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502044953"
          target="_blank"
          style="margin-left: 44px"
        >
          京公网安备11010502044953号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getKfPhone } from '@/api/home'

export default {
  data() {
    return {
      phone: '010-56034351',
      year: 2024
    }
  },
  mounted() {
    let date = new Date()
    this.year = date.getFullYear()
  },
  methods: {
    async getKfPhone() {
      let res = await getKfPhone()
      this.phone = res.data.kfPhone
    }
  }
}
</script>

<style scoped lang="scss">
.footerBox {
  width: 1200px;
  height: 275px;
  // background: pink;
}

.footerBoxtop {
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 81px;

  span {
    display: flex;
    justify-content: center;
    width: 386px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
  }
}

.footerBoxBottom {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
    color: #ffffff;
  }

  span {
    font-family: PingFang SC;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
  }

  div {
    font-family: PingFang SC;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    position: relative;
    margin-top: 24px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
      position: absolute;
      right: 27.5%;
      top: -2px;
    }
  }
}
</style>
